export const eng = {
    home: 'Home',
    projects: 'Projects',
    contact: 'Contact',
    apps: 'Apps',
    fullstack_developer: 'Full Stack Developer',
    know_more_btn: 'Know more',
    bio: `After working for more than a decade in other sectors, I decided to dedicate myself professionally to my true passion, web development. I am in love with technology and I like to create different solutions that really serve people and add value. In my experience, what distinguishes a good job from a great job is having understood the need and responding to expectations. My spectrum of skills includes both front-end and back-end building and for this I use the latest technologies in the world of web development.`,
    tech_stack: 'TECH STACK',
    resume: 'Resume',
    projects_title: 'PROJECTS',
    portfolio: `This is the website where we are. It is my personal portfolio where you can access all my projects.`,
    thegoodfilm: `If you love movies do not hesitate. Register and track your favorite movies, and also make your personal diary to remember when, where and especially with whom you saw that movie. Ironhack final project`,
    allcitycocktails: `In love with cocktails? just register and access to infinite recipes. If you feel creative you can also add your own creations. What are you waiting for?. Ironhack second project`,
    funkohollywood: `Show how much you know about cinema by playing this exciting trivial game where the protagonists are our beloved Funkos. Ironhack first project`,
    site: 'Site',
    current_site: 'Current site',
    apps_title: 'APPS',
    criptoconverter: 'CRYPTOCONVERTER',
    bb_quotes: 'BREAKING BAD QUOTES',
    veterinary_booking: 'VETERINARY BOOKING',
    budget: 'BUDGET',
    image_search: 'IMAGE SEARCH',
    lyric_search: 'LYRICS SEARCH',
    car_assurance: 'CAR ASSURANCE CALCULATOR',
    weather: 'WEATHER',
    contact_f: 'Would you like to contact with me?? Great!!',
    footer: 'Created by Sara Palacios',
    pagenotfound1: `Oops, we can't find that page. Click`,
    pagenotfound2: `here`,
    pagenotfound3: `to go Home.`,




};